.Imprint {
  position: absolute;

  h4 {
    margin-bottom: 2rem;
  }

  .Text {
    padding: 2rem;
    .Paragraph {
      max-width: 768px;
      margin-top: 1rem;
      h4 {
        margin-bottom: 1rem;
      }
    }
  }

  .Links {
    padding: 2rem;
    background: #ffffff;
    background-color: white;

    h4 {
      margin-bottom: 1rem;
    }
  }
}
