.Navigation {
  width: 100%;
  position: absolute;
  padding: 2rem;

  h4 {
    margin-bottom: 1rem;
  }

  .Links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .Menu {
    h2 {
      margin-bottom: 3rem;
    }

    h4 {
      margin-bottom: 0.3rem;
      background-color: #ffffff;
      padding: 1rem;
      border-left: 2px solid #3e3e3e;
      margin-bottom: 0.5rem;
      transition: all 1s ease-out;

      &:hover {
        margin-left: 1rem;
      }
    }
  }

  .External {
    @extend .Menu;

    h4 {
      width: fit-content;
      border-left: none;
    }
  }
}
