.App {
  min-width: 350px;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  @media only screen and (max-width: 768px) {
    padding: 0 0 2rem 0;
  }
  overflow: hidden;

  .Content {
    position: relative;
    overflow: hidden;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    animation: fade-in 500ms;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
