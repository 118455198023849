.Gallery {
  position: absolute;
  min-width: 100%;
  min-height: 500px;
  height: fit-content;
  padding: 2rem;

  h2 {
    margin-bottom: 4rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  .ImgGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    gap: 3rem;
  }

  .GalleryCard {
    background: #ffffff;
    padding: 3rem;
    @media only screen and (max-width: 768px) {
      padding: 2rem;
    }
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      max-height: 30vh;
      //max-width: 500px;
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: 2rem;
    }

    .InfoContainer {
      width: 100%;
      text-align: center;
    }
  }
}
