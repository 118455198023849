.WelcomeContainer {
  width: 100%;
  position: absolute;
  .Welcome {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow: visible;
    h4 {
      margin-bottom: 1rem;
    }

    h2 {
      margin-bottom: 4rem;
    }

    .Message {
      display: grid;
      gap: 6rem;
      width: fit-content;
      background: #ffffffaa;
      padding: 2rem;
      border-radius: 10px;

      .Citat {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 500px;

        p {
          font-size: 25px;
          @media only screen and (max-width: 768px) {
            font-size: 20px;
          }
          margin-bottom: 4rem;
        }

        .Author {
          h4 {
            margin-bottom: 0.5rem;
            font-weight: 600;
            color: #3e3e3e;
          }

          div {
            width: 50px;
            border-bottom: 1px solid black;
          }
        }
      }
    }
    .ImageContainer {
      position: absolute;
      z-index: -1;
      height: 100vh;
      width: 100%;
      left: 0;
      top: 0;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: 50% 18%;
      }
    }
  }
}
