.Vita {
  position: absolute;
  padding: 2rem;

  h4 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 4rem;
  }

  .Container {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: auto auto auto;
    @media only screen and (max-width: 960px) {
      grid-template-columns: repeat(1, 1fr);
    }
    gap: 3rem;
    padding: 2rem;

    .PictureContainer {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      .Picture {
        position: relative;
        height: 200px;
        width: 150px;
        overflow: hidden;
        border-radius: 150px;
        margin: 0 auto;

        img {
          height: 200px;
          width: 150px;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .Text {
      height: 100%;
      column-count: 2;
      @media only screen and (max-width: 1200px) {
        column-count: 1;
      }
      gap: 1rem;

      p {
        font-size: 16px;
      }

      & > :nth-child(2) {
        margin-bottom: 1rem;
      }
    }
  }
}
