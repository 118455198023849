@import url(https://fonts.googleapis.com/css2?family=Vidaloka&family=Cardo:wght@400;700&family=Heebo:wght@400;500;700&family=Josefin+Sans:wght@400;500;700&display=swap);
@font-face{font-family:HKGrotesk;src:url(/static/media/HKGrotesk-Regular.6d5a3b65.woff2) format("woff2");font-weight:normal}@font-face{font-family:HKGrotesk;src:url(/static/media/HKGrotesk-Bold.d0058668.woff2) format("woff2");font-weight:bold}@font-face{font-family:HKGrotesk;src:url(/static/media/HKGrotesk-Thin.a0111a6e.woff2) format("woff2");font-weight:thin}@font-face{font-family:HKGrotesk;src:url(/static/media/HKGrotesk-SemiBold.29326187.woff2) format("woff2");font-weight:medium}*{margin:0;padding:0;box-sizing:border-box}body{font-family:HKGrotesk,sans-serif;font-size:18px;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#3e3e3e}p{line-height:1.4}h1,h2{font-family:HKGrotesk,sans-serif;font-size:40px;font-weight:bold;line-height:1}@media only screen and (max-width: 768px){h1,h2{font-size:30px}}h1{font-size:70px}h3{font-size:26px;margin-bottom:1rem}h4{font-family:HKGrotesk,sans-serif;font-size:14px;letter-spacing:.1em;text-transform:uppercase;line-height:1.6}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}a{color:#3e3e3e;text-decoration:none;transition:all .3s ease-in-out}a:hover{color:#999}p{font-size:14px;font-weight:400}@media screen and (prefers-reduced-motion: no-preference){html,body{scroll-behavior:smooth}}
.App_App__15LM-{min-width:350px;max-width:1920px;min-height:100vh;margin:0 auto;display:flex;flex-direction:column;padding:2rem;overflow:hidden}@media only screen and (max-width: 768px){.App_App__15LM-{padding:0 0 2rem 0}}.App_App__15LM- .App_Content__LFMSe{position:relative;overflow:hidden;flex:1 1;background-repeat:no-repeat;background-size:cover;-webkit-animation:App_fade-in__22i2e 500ms;animation:App_fade-in__22i2e 500ms}@-webkit-keyframes App_fade-in__22i2e{0%{opacity:0}100%{opacity:1}}@keyframes App_fade-in__22i2e{0%{opacity:0}100%{opacity:1}}
.Header_Header__1CTzX{padding:2rem;display:flex;justify-content:space-between;margin-bottom:2rem}.Header_Header__1CTzX h4{font-size:14px}
.Footer_Footer__37hEc{display:flex;justify-content:space-between;padding:2rem 2rem 0rem 2rem}.Footer_Footer__37hEc .Footer_Up__2Ibx0{-webkit-transform:rotate(-90deg);transform:rotate(-90deg);width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.Footer_Footer__37hEc .Footer_Up__2Ibx0 a{padding:.5rem}
.Welcome_WelcomeContainer__1UalN{width:100%;position:absolute}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1{width:100%;display:flex;flex-direction:column;padding:2rem;overflow:visible}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 h4{margin-bottom:1rem}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 h2{margin-bottom:4rem}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X{display:grid;grid-gap:6rem;gap:6rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;background:#ffffffaa;padding:2rem;border-radius:10px}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X .Welcome_Citat__1mP-l{display:flex;flex-direction:column;justify-content:space-between;max-width:500px}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X .Welcome_Citat__1mP-l p{font-size:25px;margin-bottom:4rem}@media only screen and (max-width: 768px){.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X .Welcome_Citat__1mP-l p{font-size:20px}}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X .Welcome_Citat__1mP-l .Welcome_Author__1mHFP h4{margin-bottom:.5rem;font-weight:600;color:#3e3e3e}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_Message__3Gg_X .Welcome_Citat__1mP-l .Welcome_Author__1mHFP div{width:50px;border-bottom:1px solid #000}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_ImageContainer__1X2eH{position:absolute;z-index:-1;height:100vh;width:100%;left:0;top:0}.Welcome_WelcomeContainer__1UalN .Welcome_Welcome__3EPr1 .Welcome_ImageContainer__1X2eH img{width:100%;height:100%;display:block;object-fit:cover;object-position:50% 18%}
.Gallery_Gallery__39R_o{position:absolute;min-width:100%;min-height:500px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:2rem}.Gallery_Gallery__39R_o h2{margin-bottom:4rem}.Gallery_Gallery__39R_o h4{margin-bottom:1rem}.Gallery_Gallery__39R_o .Gallery_ImgGrid__lsF9G{display:grid;grid-template-columns:repeat(3, 1fr);grid-gap:3rem;gap:3rem}@media only screen and (max-width: 1200px){.Gallery_Gallery__39R_o .Gallery_ImgGrid__lsF9G{grid-template-columns:repeat(2, 1fr)}}@media only screen and (max-width: 768px){.Gallery_Gallery__39R_o .Gallery_ImgGrid__lsF9G{grid-template-columns:repeat(1, 1fr)}}.Gallery_Gallery__39R_o .Gallery_GalleryCard__3jlSX{background:#fff;padding:3rem;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content;display:flex;flex-direction:column;justify-content:space-between}@media only screen and (max-width: 768px){.Gallery_Gallery__39R_o .Gallery_GalleryCard__3jlSX{padding:2rem}}.Gallery_Gallery__39R_o .Gallery_GalleryCard__3jlSX img{max-height:30vh;width:100%;height:auto;display:block;object-fit:contain;margin-bottom:1rem}.Gallery_Gallery__39R_o .Gallery_GalleryCard__3jlSX h4{margin-bottom:2rem}.Gallery_Gallery__39R_o .Gallery_GalleryCard__3jlSX .Gallery_InfoContainer__aSQaj{width:100%;text-align:center}
.Loader_Loader__3w7V8{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%);transform:translate(-50%);will-change:width,background}.Loader_Loader__3w7V8 svg{width:15px;-webkit-transform:translateY(5px) scale(0.5) rotate(-30deg);transform:translateY(5px) scale(0.5) rotate(-30deg);opacity:0}.Loader_Loader__3w7V8>:first-child{-webkit-animation:Loader_example__2zH0H 2s ease infinite;animation:Loader_example__2zH0H 2s ease infinite}.Loader_Loader__3w7V8>:nth-child(2){-webkit-animation:Loader_example__2zH0H 2s ease infinite .3s;animation:Loader_example__2zH0H 2s ease infinite .3s}.Loader_Loader__3w7V8>:nth-child(3){-webkit-animation:Loader_example__2zH0H 2s ease infinite .6s;animation:Loader_example__2zH0H 2s ease infinite .6s}@-webkit-keyframes Loader_example__2zH0H{0%{-webkit-transform:translateY(5px) scale(0.5) rotate(-30deg);transform:translateY(5px) scale(0.5) rotate(-30deg);opacity:0}50%{-webkit-transform:translateY(-5px) scale(0.8) rotate(30deg);transform:translateY(-5px) scale(0.8) rotate(30deg);opacity:1}100%{-webkit-transform:translateY(5px) scale(0.8) rotate(-30deg);transform:translateY(5px) scale(0.8) rotate(-30deg);opacity:0}}@keyframes Loader_example__2zH0H{0%{-webkit-transform:translateY(5px) scale(0.5) rotate(-30deg);transform:translateY(5px) scale(0.5) rotate(-30deg);opacity:0}50%{-webkit-transform:translateY(-5px) scale(0.8) rotate(30deg);transform:translateY(-5px) scale(0.8) rotate(30deg);opacity:1}100%{-webkit-transform:translateY(5px) scale(0.8) rotate(-30deg);transform:translateY(5px) scale(0.8) rotate(-30deg);opacity:0}}
.Vita_Vita__WIvm2{position:absolute;padding:2rem}.Vita_Vita__WIvm2 h4{margin-bottom:1rem}.Vita_Vita__WIvm2 h2{margin-bottom:4rem}.Vita_Vita__WIvm2 .Vita_Container__1oaXz{background-color:#fff;display:grid;grid-template-columns:auto auto auto;grid-gap:3rem;gap:3rem;padding:2rem}@media only screen and (max-width: 960px){.Vita_Vita__WIvm2 .Vita_Container__1oaXz{grid-template-columns:repeat(1, 1fr)}}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_PictureContainer__slXpN{padding:0 .5rem;display:flex;align-items:center}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_PictureContainer__slXpN .Vita_Picture__3eY7h{position:relative;height:200px;width:150px;overflow:hidden;border-radius:150px;margin:0 auto}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_PictureContainer__slXpN .Vita_Picture__3eY7h img{height:200px;width:150px;object-fit:cover;object-position:top}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_Text__11_BQ{height:100%;-webkit-column-count:2;column-count:2;grid-gap:1rem;gap:1rem}@media only screen and (max-width: 1200px){.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_Text__11_BQ{-webkit-column-count:1;column-count:1}}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_Text__11_BQ p{font-size:16px}.Vita_Vita__WIvm2 .Vita_Container__1oaXz .Vita_Text__11_BQ>:nth-child(2){margin-bottom:1rem}
.Exhibitions_Exhibitions__2Xl58{position:absolute;padding:2rem;min-width:100%;min-height:500px}.Exhibitions_Exhibitions__2Xl58 h4{margin-bottom:1rem}.Exhibitions_Exhibitions__2Xl58 h2{margin-bottom:4rem}.Exhibitions_Exhibitions__2Xl58 .Exhibitions_Row__2OL4R{display:flex;background:#fff;margin-bottom:.5rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.Exhibitions_Exhibitions__2Xl58 .Exhibitions_Row__2OL4R h4{padding:.5rem;margin:0}
.Navigation_Navigation__1Ylka{width:100%;position:absolute;padding:2rem}.Navigation_Navigation__1Ylka h4{margin-bottom:1rem}.Navigation_Navigation__1Ylka .Navigation_Links__2NgBM{display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:6rem;gap:6rem}@media only screen and (max-width: 768px){.Navigation_Navigation__1Ylka .Navigation_Links__2NgBM{grid-template-columns:repeat(1, 1fr)}}.Navigation_Navigation__1Ylka .Navigation_Menu__1u5Cv h2,.Navigation_Navigation__1Ylka .Navigation_External__3xi8a h2{margin-bottom:3rem}.Navigation_Navigation__1Ylka .Navigation_Menu__1u5Cv h4,.Navigation_Navigation__1Ylka .Navigation_External__3xi8a h4{margin-bottom:.3rem;background-color:#fff;padding:1rem;border-left:2px solid #3e3e3e;margin-bottom:.5rem;transition:all 1s ease-out}.Navigation_Navigation__1Ylka .Navigation_Menu__1u5Cv h4:hover,.Navigation_Navigation__1Ylka .Navigation_External__3xi8a h4:hover{margin-left:1rem}.Navigation_Navigation__1Ylka .Navigation_External__3xi8a h4{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-left:none}
.Imprint_Imprint__3UkG_{position:absolute}.Imprint_Imprint__3UkG_ h4{margin-bottom:2rem}.Imprint_Imprint__3UkG_ .Imprint_Text__1_tQ8{padding:2rem}.Imprint_Imprint__3UkG_ .Imprint_Text__1_tQ8 .Imprint_Paragraph__1DxkG{max-width:768px;margin-top:1rem}.Imprint_Imprint__3UkG_ .Imprint_Text__1_tQ8 .Imprint_Paragraph__1DxkG h4{margin-bottom:1rem}.Imprint_Imprint__3UkG_ .Imprint_Links__dqA_-{padding:2rem;background:#fff;background-color:#fff}.Imprint_Imprint__3UkG_ .Imprint_Links__dqA_- h4{margin-bottom:1rem}
