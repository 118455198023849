.Exhibitions {
  position: absolute;
  padding: 2rem;
  min-width: 100%;
  min-height: 500px;

  h4 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 4rem;
  }

  .Row {
    display: flex;
    background: #ffffff;
    margin-bottom: 0.5rem;
    width: fit-content;

    h4 {
      padding: 0.5rem;
      margin: 0;
    }
  }
}
