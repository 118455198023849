.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  will-change: width, background;

  svg {
    width: 15px;
    transform: translateY(5px) scale(0.5) rotate(-30deg);
    opacity: 0;
  }

  & > :first-child {
    animation: example 2s ease infinite;
  }

  & > :nth-child(2) {
    animation: example 2s ease infinite 0.3s;
  }

  & > :nth-child(3) {
    animation: example 2s ease infinite 0.6s;
  }
}

@keyframes example {
  0% {
    transform: translateY(5px) scale(0.5) rotate(-30deg);
    opacity: 0;
  }
  50% {
    transform: translateY(-5px) scale(0.8) rotate(30deg);
    opacity: 1;
  }
  100% {
    transform: translateY(5px) scale(0.8) rotate(-30deg);
    opacity: 0;
  }
}
