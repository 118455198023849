@import url('https://fonts.googleapis.com/css2?family=Vidaloka&family=Cardo:wght@400;700&family=Heebo:wght@400;500;700&family=Josefin+Sans:wght@400;500;700&display=swap');

@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk-Regular.woff2) format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk-Bold.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk-Thin.woff2) format('woff2');
  font-weight: thin;
}

@font-face {
  font-family: HKGrotesk;
  src: url(./fonts/HKGrotesk-SemiBold.woff2) format('woff2');
  font-weight: medium;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  // font-family: 'Heebo', sans-serif;
  // font-family: 'Josefin Sans', sans-serif;
  font-family: HKGrotesk, sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3e;
}

p {
  line-height: 1.4;
}

h1,
h2 {
  font-family: HKGrotesk, sans-serif;
  font-size: 40px;
  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
  font-weight: bold;
  line-height: 1;
}

h1 {
  font-size: 70px;
}

h3 {
  font-size: 26px;
  margin-bottom: 1rem;
}

h4 {
  // font-family: 'Heebo', sans-serif;
  font-family: HKGrotesk, sans-serif;
  font-size: 14px;
  // @media only screen and (max-width: 768px) {
  //   font-size: 12px;
  // }
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #3e3e3e;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #999999;
  }
}

p {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html,
  body {
    scroll-behavior: smooth;
  }
}
