.Footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 0rem 2rem;

  .Up {
    transform: rotate(-90deg);
    width: fit-content;
    height: fit-content;

    a {
      padding: 0.5rem;
    }
  }
}
